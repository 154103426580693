import { Field } from "../../../types/field";
import { getOneMonthAgoOrOpening, getOpeningStockDate, getToday } from "../../../services/dateUtils";
import { stockTypeValues } from "../reportUtils";
import { inTransactionTypeValues, materialTypeValues } from "../../../standardEntities/materialInward";
import { projectTypeValues } from "../../../standardEntities/project";
import { outTransactionTypeValues } from "../../../standardEntities/materialOutward";
import { workTypeValues } from "../../../standardEntities/cbSheetFields";
import { translatePicklistValue } from "../../../services/fieldsUtils";
import { storeTypeValues } from "../formFields/stockListingFormFields";
import { Column } from "../../../types/column";

export const stockLedgerCategoryValues = [
  { value: "LOCATOR_DETAIL", label: "Stock Ledger Locator Wise Report" },
  { value: "PROJECT_DETAIL", label: "Stock Ledger Project Wise Report" },
];

export const getStockLedgerFields = (): Field[] => [
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "reportCategory",
        label: "Report Category",
        type: "picklist",
        defaultValue: "LOCATOR_DETAIL",
        props: {
          width: "25vw",
        },
        values: stockLedgerCategoryValues,
        linkedFields: [
          {
            name: "docLocatorName",
            value: null,
          },
          {
            name: "docLocatorCode",
            value: null,
          },
          {
            name: "docCompanyName",
            value: null,
          },
          {
            name: "docCompanyCode",
            value: null,
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    visibility: { type: "EQUALS", field: "reportCategory", values: ["dummy"] },
    childFields: [
      {
        name: "stockType",
        label: "Stock Type",
        type: "picklist",
        defaultValue: "P",
        values: stockTypeValues,
        props: {
          width: "18vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    visibility: { type: "NIN", field: "reportCategory", values: ["PROJECT_SUMMARY", "PROJECT_DETAIL"] },
    childFields: [
      {
        name: "docLocatorName",
        label: "Inward Locator Name",
        allowNull: true,
        props: {
          width: "25vw",
        },
        type: "lookup",
        lookupType: "locator",
        lookupFieldName: "locatorCode",
        lookupCategory: "docLocator",
        lookupParams: [
          {
            key: "locatorType",
            resolveValue: true,
            valueFieldName: "docLocatorType",
          },
          {
            key: "projectCode",
            resolveValue: true,
            valueFieldName: "projectCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "locatorType",
              values: ["PS", "CS"],
            },
            {
              type: "EQUALS",
              field: "locatorCode",
              resolveValue: true,
              valueFieldName: "docLocatorCode",
            },
            {
              type: "EQUALS",
              field: "locatorType",
              resolveValue: true,
              valueFieldName: "docLocatorType",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "docStateCode",
            },
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "docCompanyCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docLocatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorType",
            fieldName: "docLocatorType",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyCode",
            fieldName: "docCompanyCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyName",
            fieldName: "docCompanyName",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyCode",
            fieldName: "docCompanyCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyName",
            fieldName: "docCompanyName",
          },
          {
            additionalFieldName: "station",
            lookupFieldName: "stateCode",
            fieldName: "docStateCode",
          },
          {
            additionalFieldName: "station",
            lookupFieldName: "stateName",
            fieldName: "docStateName",
          },
        ],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: { type: "NIN", field: "reportCategory", values: ["PROJECT_SUMMARY", "PROJECT_DETAIL"] },
    childFields: [
      {
        name: "docLocatorType",
        label: "Store Type",
        type: "picklist",
        defaultValue: "",
        allowNull: true,
        values: storeTypeValues,
        props: {
          width: "11vw",
        },
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
      },
      {
        name: "docLocatorCode",
        label: "Locator",
        type: "text",
        allowNull: true,
        props: {
          width: "6vw",
        },
        autoFilled: true,
      },
      {
        name: "docCompanyCode",
        label: "Company",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: { type: "NIN", field: "reportCategory", values: ["PROJECT_SUMMARY", "PROJECT_DETAIL"] },
    childFields: [
      {
        name: "docCompanyName",
        label: "Company Name",
        type: "lookup",
        lookupType: "company",
        // lookupCategory: "workClosureContractor",
        lookupFilter: {
          type: "OR",
          filters: [
            {
              type: "IN",
              field: "contractorFlg",
              values: [true],
            },
            {
              type: "IN",
              field: "partnerFlg",
              values: [true],
            },
          ],
        },
        // lookupFilter: { type: "AND", filters: [{ type: "IN", field: "contractorFlg", values: [true] }] },
        props: {
          width: "25vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docCompanyCode",
          },
        ],
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "docStateCode",
        label: "State Code",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
      {
        name: "docStateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        lookupCategory: "reportingStates",
        defaultValue: null,
        allowUpdate: false,
        lookupFilter: {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "docStateCode",
        },
        lookupParams: [
          {
            key: "locatorCode",
            resolveValue: true,
            valueFieldName: "docLocatorCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docStateCode",
          },
        ],
        props: {
          width: "17vw",
        },
        linkedFields: [
          {
            name: "projectName",
            value: "",
          },
          {
            name: "projectCode",
            value: "",
          },
          {
            name: "projectType",
            value: "",
          },
        ],
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "projectName",
        label: "Project Name",
        type: "lookup",
        lookupType: "project",
        lookupCategory: "docProject",
        allowNull: true,
        props: {
          width: "25vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "projectCode",
              resolveValue: true,
              valueFieldName: "projectCode",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "docStateCode",
            },
            {
              type: "EQUALS",
              field: "projectType",
              resolveValue: true,
              valueFieldName: "projectType",
            },
          ],
        },
        lookupParams: [
          {
            key: "docLocatorCode",
            resolveValue: true,
            valueFieldName: "docLocatorCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "projectCode",
          },
          {
            lookupFieldName: "description",
            fieldName: "projectType",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        allowNull: true,
        values: projectTypeValues,
        props: {
          width: "12vw",
        },
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "projectCode",
            },
          ],
        },
      },
      {
        name: "projectCode",
        label: "Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        defaultValue: null,
        props: {
          width: "12vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "itemName",
        label: "Item Name",
        type: "lookup",
        lookupType: "item",
        lookupFieldName: "itemName",
        allowNull: true,
        props: {
          width: "25vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "itemCode",
              resolveValue: true,
              valueFieldName: "itemCode",
            },
            {
              type: "EQUALS",
              field: "itemGroupId",
              resolveValue: true,
              valueFieldName: "itemGroupId",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "itemCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "itemCustCode",
            fieldName: "itemCustCode",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "materialType",
        label: "Material Type",
        type: "picklist",
        allowNull: true,
        values: materialTypeValues,
        props: {
          width: "12vw",
        },
      },
      {
        name: "itemCustCode",
        label: "Part Code",
        type: "text",
        allowNull: true,
        autoFilled: true,
        props: {
          width: "12vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "sections",
        type: "formSection",
        childFields: [
          {
            name: "stockOpeningDate",
            label: "Stock Opening Date",
            type: "date",
            defaultValue: getOneMonthAgoOrOpening(),
            constraints: [
              {
                type: "DATE_RANGE",
                minDate: getOpeningStockDate(),
                maxDateScript: {
                  valueFields: ["stockClosingDate"],
                },
                beforeNow: true,
              },
            ],
          },
        ],
      },
      {
        name: "sections",
        type: "formSection",
        childFields: [
          {
            name: "stockClosingDate",
            label: "Stock Closing Date",
            type: "date",
            defaultValue: getToday(),
            constraints: [
              {
                type: "DATE_RANGE",
                minDateScript: {
                  operation: "minDate",
                  valueFields: ["stockOpeningDate"],
                  values: [getOpeningStockDate()],
                },
                beforeNow: true,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const stockLedgerProjectReport: Column[] = [
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  { name: "ownerCode", label: "Owner Code", type: "text" },
  { name: "ownerName", label: "Owner Name", type: "text" },
  {
    name: "category",
    label: "Category",
    type: "text",
  },
  {
    name: "docNo",
    label: "Doc No",
    type: "text",
  },
  {
    name: "docDate",
    label: "Doc Date",
    type: "date",
  },
  {
    name: "transactionType",
    label: "Transaction/Work Type",
    type: "picklist",
    values: [...inTransactionTypeValues, ...outTransactionTypeValues, ...workTypeValues, { value: "TO", label: "Total" }],
  },
  {
    name: "materialType",
    label: "Material Type",
    type: "picklist",
    values: materialTypeValues,
  },
  {
    name: "workId",
    label: "Work Id",
    type: "text",
  },
  {
    name: "submit",
    label: "Submit",
    type: "checkbox",
  },
  {
    name: "rowId",
    label: "Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "docQuantity",
    label: "Doc Quantity",
    type: "number",
  },
  {
    name: "inwardQuantity",
    label: "Qty Inward",
    type: "number",
  },
  {
    name: "outwardQuantity",
    label: "Qty Outward",
    type: "number",
  },
  {
    name: "balanceQuantity",
    label: "Balance Qty",
    type: "number",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
];

export const stockLedgerLocatorReport: Column[] = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "locatorType",
    label: "Locator Type",
    type: "picklist",
    values: storeTypeValues,
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Company Name",
    type: "text",
  },
  ...stockLedgerProjectReport,
];
